import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {PipesModule} from '../../shared/pipes/pipes.module'
import {DirectivesModule} from '../../shared/directives/directives.module'
import {SellingPointBarComponent} from './selling-point-bar.component'
import {SellingPointItemComponent} from './selling-point-item/selling-point-item.component'
import {ButtonModule} from '../button/button.module'
import {InfoBoxModule} from '../info-box/info-box.module'

@NgModule({
  declarations: [SellingPointBarComponent, SellingPointItemComponent],
  exports: [SellingPointBarComponent, SellingPointItemComponent],
  imports: [CommonModule, FontAwesomeModule, InfoBoxModule, PipesModule, DirectivesModule, ButtonModule],
})
export class SellingPointBarModule {}
