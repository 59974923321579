<div
  class="flex flex-nowrap items-center justify-center space-x-sm text-xs font-medium text-white"
  [class.item-hidden]="itemHidden"
  [class.cursor-pointer]="showInfoIcon"
>
  @if (icon) {
    <fa-icon [icon]="icon"></fa-icon>
  }
  <div class="text-center">
    <ng-content></ng-content>
  </div>
  @if (showInfoIcon) {
    <fa-icon [icon]="infoIcon"></fa-icon>
  }
</div>
