import {AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList} from '@angular/core'
import {SellingPointItemComponent} from './selling-point-item/selling-point-item.component'
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight'

@Component({
  selector: 'cft-selling-point-bar',
  templateUrl: './selling-point-bar.component.html',
  styleUrls: ['./selling-point-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellingPointBarComponent implements AfterViewInit {
  @Input() align: 'center' | 'left' = 'center'

  @Input() hasSolidBackground = false

  @Input() mdGapSize: 'xl' | '2xl' = '2xl'

  @ContentChildren(SellingPointItemComponent) sellingPointItems!: QueryList<SellingPointItemComponent>

  leftChevronIcon = faChevronLeft
  rightChevronIcon = faChevronRight

  ngAfterViewInit(): void {
    const firstItem = this.sellingPointItems.get(0)
    if (firstItem) {
      firstItem.itemHidden = false
    }
  }

  nextItem(): void {
    let currentIndex = -1
    const item = this.sellingPointItems.find((item, index) => {
      currentIndex = index
      return !item.itemHidden
    })

    const next = this.sellingPointItems.get(currentIndex + 1)
    const first = this.sellingPointItems.first
    if (item) {
      item.itemHidden = true
      if (next) {
        next.itemHidden = false
      } else {
        first.itemHidden = false
      }
    }
  }

  previousItem(): void {
    let currentIndex = -1
    const item = this.sellingPointItems.find((item, index) => {
      currentIndex = index
      return !item.itemHidden
    })

    const prev = this.sellingPointItems.get(currentIndex - 1)
    const last = this.sellingPointItems.last
    if (item) {
      item.itemHidden = true
      if (prev) {
        prev.itemHidden = false
      } else {
        last.itemHidden = false
      }
    }
  }
}
