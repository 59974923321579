import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle'

@Component({
  selector: 'cft-selling-point-item',
  templateUrl: './selling-point-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellingPointItemComponent {
  @Input()
  readonly icon?: IconDefinition

  @Input()
  showInfoIcon = false

  readonly infoIcon = faInfoCircle
  private hidden = true

  set itemHidden(value) {
    this.hidden = value
    this.cdr.detectChanges()
  }
  get itemHidden() {
    return this.hidden
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}
}
