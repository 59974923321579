<div
  class="border-white-50 border-t text-president-500"
  [ngClass]="{
    'transparent-background': !hasSolidBackground,
    'bg-president-400': hasSolidBackground
  }"
>
  <div
    class="h-layout flex h-[60px] flex-row items-center justify-between md:h-[48px]"
    [class.md:justify-center]="align === 'center'"
    [class.md:justify-start]="align === 'left'"
  >
    <button class="btn block max-w-[2rem] bg-white p-2xs md:hidden" cft-button (click)="previousItem()">
      <fa-icon [icon]="leftChevronIcon"></fa-icon>
    </button>
    <div
      class="items flex flex-row items-center justify-center gap-xs"
      [class.md:gap-xl]="mdGapSize === 'xl'"
      [class.md:gap-2xl]="mdGapSize === '2xl'"
    >
      <ng-content></ng-content>
    </div>
    <button class="btn block max-w-[2rem] bg-white p-2xs md:hidden" cft-button (click)="nextItem()">
      <fa-icon [icon]="rightChevronIcon"></fa-icon>
    </button>
  </div>
</div>
